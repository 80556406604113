import {
  JOURNEY_STEPS,
  JOURNEY_BASIC_INFO,
  STEP_FIELDS,
  STEP_INDEX,
  JOURNEY_REQUIREMENTS,
  JOURNEY_BUSINESS_KYC,
  SUBMIT_JOURNEY_FORM,
  SUBMIT_JOURNEY_FORM_SUCCESS,
  SUBMIT_JOURNEY_FORM_ERROR,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  SUBMIT_PRODUCTS,
  SUBMIT_PRODUCTS_SUCCESS,
  SUBMIT_PRODUCTS_ERROR,
  GET_JOURNEY_DETAILS,
  GET_JOURNEY_DETAILS_SUCCESS,
  GET_JOURNEY_DETAILS_ERROR,
  CAMPAIGN_DATA,
  GET_BRE_RESULT,
  GET_BRE_RESULT_SUCCESS,
  GET_BRE_RESULT_ERROR,
  JOURNEY_BUSINESS_INFO,
  GET_APPLICATION_SUMMARY,
  GET_APPLICATION_SUMMARY_SUCCESS,
  GET_APPLICATION_SUMMARY_ERROR,
  GET_BANK_LIST,
  GET_BANK_LIST_SUCCESS,
  GET_BANK_LIST_ERROR,
  GET_CITY_LIST,
  GET_CITY_LIST_SUCCESS,
  GET_CITY_LIST_ERROR,
  SEND_SELFIE,
  SEND_SELFIE_SUCCESS,
  SEND_SELFIE_ERROR
} from '../contants';

export const getJourneyDetails = (history) => ({
  type: GET_JOURNEY_DETAILS,
  payload: {history}
});

export const getJourneyDetailsSuccess = (payload) => ({
  type: GET_JOURNEY_DETAILS_SUCCESS,
  payload: payload
});

export const getJourneyDetailsError = (payload) => ({
  type: GET_JOURNEY_DETAILS_ERROR,
  payload: payload
});

export const updateJourneySteps = (payload) => ({
  type: JOURNEY_STEPS,
  payload
});

export const updateStepFields = (payload) => ({
  type: STEP_FIELDS,
  payload
});

export const updateFinancialFieldsOnIncorporationDate = (dateStr, stepFields) => {

  const dateObj = new Date(dateStr.split('/').reverse().join('-'));
  const month = dateObj.getMonth() + 1;  // getMonth() returns 0-11
  const year = dateObj.getFullYear();

  let fields = stepFields;
  if(year === new Date().getFullYear()){
    if((month <= 3) && ((new Date().getMonth()+1) > 9)){
      delete fields.lastProvisionalRevenue;
      delete fields.pbtLastProvisional;
      // fields = {
      //   ...fields,
      //   revenueLastAudited: {
      //     isMendatory: true
      //   },
      //   pbtLastAudited: {
      //     isMendatory: true
      //   }
      // }
    } else if((month <= 3) && ((new Date().getMonth()+1) < 9)) {
      delete fields.revenueLastAudited;
      delete fields.pbtLastAudited;
      // fields = {
      //   ...fields,
      //   lastProvisionalRevenue: {
      //     isMendatory: true
      //   },
      //   pbtLastProvisional: {
      //     isMendatory: true
      //   }
      // }
    } else if((month > 3)){
      delete fields.revenueLastAudited;
      delete fields.pbtLastAudited;
      delete fields.lastProvisionalRevenue;
      delete fields.pbtLastProvisional
    }
  }
  if((year === new Date().getFullYear() - 1) && ((new Date().getMonth()+1) > 9)){
    delete fields.lastProvisionalRevenue;
    delete fields.pbtLastProvisional;
    // fields = {
    //   ...fields,
    //   revenueLastAudited: {
    //     isMendatory: true
    //   },
    //   pbtLastAudited: {
    //     isMendatory: true
    //   }
    // }
  } else if((year === new Date().getFullYear() - 1) && ((new Date().getMonth()+1) <= 9)){
    if(month > 3){
      delete fields.revenueLastAudited;
      delete fields.pbtLastAudited;
      // fields = {
      //   ...fields,
      //   lastProvisionalRevenue: {
      //     isMendatory: true
      //   },
      //   pbtLastProvisional: {
      //     isMendatory: true
      //   }
      // }
    }
  }
  if((year === new Date().getFullYear() - 2) && ((new Date().getMonth()+1) > 9)){
    delete fields.lastProvisionalRevenue;
    delete fields.pbtLastProvisional;
    // fields = {
    //   ...fields,
    //   revenueLastAudited: {
    //     isMendatory: true
    //   },
    //   pbtLastAudited: {
    //     isMendatory: true
    //   }
    // }
  } else if((year === new Date().getFullYear() - 2) && ((new Date().getMonth()+1) <= 9)){

  }

  return {
    type: STEP_FIELDS,
    payload: fields
  }
}

export const updateBasicInfo = (payload) => {
  return {
    type: JOURNEY_BASIC_INFO,
    payload
  }
}

export const updateBusinessInfo = (payload) => {
  return {
    type: JOURNEY_BUSINESS_INFO,
    payload
  }
}

export const updateRequirements = (payload) => {
  return {
    type: JOURNEY_REQUIREMENTS,
    payload: payload
  }
}

export const updateBusinessKyc = (payload) => {
  return {
    type: JOURNEY_BUSINESS_KYC,
    payload
  }
}

export const updateDocumentsOnIncorporationDate = (dateStr, stepFields) => {
  const dateObj = new Date(dateStr.split('/').reverse().join('-'));
  const month = dateObj.getMonth() + 1;  // getMonth() returns 0-11
  const year = dateObj.getFullYear();

  const fields = stepFields;

  if(year === new Date().getFullYear()){
    if(month > 3){
      delete fields['financialStatementLastFY'];
      delete fields['financialStatementLastFY-1'];
      delete fields['financialStatementLastFY-2'];
    } else {
      delete fields['financialStatementLastFY-1'];
      delete fields['financialStatementLastFY-2'];
    }
  }

  if(year === (new Date().getFullYear()-1)){
    if(month > 3){
      delete fields['financialStatementLastFY-1'];
      delete fields['financialStatementLastFY-2'];
    } else {
      delete fields['financialStatementLastFY-2'];
    }
  }

  if(year === (new Date().getFullYear() - 2)){
    if(month > 3){
      delete fields['financialStatementLastFY-2'];
    }
  }

  return {
    type: STEP_FIELDS,
    payload: fields
  }
};

export const updatePurposeOfVisit = (purpose, stepFields) => {

  let fields = stepFields;
  
  if(purpose === 'quotation'){
    delete fields?.registrationNumber;
    fields = {
      ...fields,
      rtoCode: {
        isMendatory: true
      }
    }
  } else if(purpose === 'buy'){
    delete fields?.rtoCode
    fields = {
      ...fields,
      registrationNumber: {
        isMendatory: true
      }
    }
  }

  return {
    type: STEP_FIELDS,
    payload: fields
  }
}

export const updateTypeOfBusiness = (type, stepFields) => {

  let fields = stepFields;

  if((type === 'Fleet-Passenger Mobility') || (type === 'Fleet-Goods Mobility')){
    fields = {
      ...fields,
      revenueBreakup: {
        ...fields?.revenueBreakup,
        isMendatory: true
      }
    }
  } else {
    delete fields?.revenueBreakup
  }

  return {
    type: STEP_FIELDS,
    payload: fields
  }
}

export const submitJourneyForm = (form, history, handleNext, next, steps, step) => ({
  type: SUBMIT_JOURNEY_FORM,
  payload: { form, handleNext, next, steps, step, history }
});

export const submitJourneyFormSuccess = (payload) => ({
  type: SUBMIT_JOURNEY_FORM_SUCCESS,
  payload: payload
});

export const submitJourneyFormError = (payload) => ({
  type: SUBMIT_JOURNEY_FORM_ERROR,
  payload: payload
});

export const getProductList = (payload) =>({
  type: GET_PRODUCT_LIST,
  payload: payload
});

export const getProductListSuccess = (payload) => ({
  type: GET_PRODUCT_LIST_SUCCESS,
  payload: payload
});

export const getProductListError = (payload) => ({
  type: GET_PRODUCT_LIST_ERROR,
  payload: payload
});

export const submitProducts = (products, history, path) => ({
  type: SUBMIT_PRODUCTS,
  payload: {products, history, path}
});

export const submitProductsSuccess = (payload) => ({
  type: SUBMIT_PRODUCTS_SUCCESS,
  payload: payload
});

export const submitProductError = (payload) => ({
  type: SUBMIT_PRODUCTS_ERROR,
  payload: payload
});

export const campaignData = (payload) => ({
  type: CAMPAIGN_DATA,
  payload: payload
});

export const getBreResult = (payload) => ({
  type: GET_BRE_RESULT,
  payload: payload
});

export const getBreResultSuccess = (payload) => ({
  type: GET_BRE_RESULT_SUCCESS,
  payload: payload
});

export const getBreResultError = (payload) => ({
  type: GET_BRE_RESULT_ERROR,
  payload: payload
});

export const getApplicationSummary = (payload) => ({
  type: GET_APPLICATION_SUMMARY,
  payload
});

export const getApplicationSummarySuccess = (payload) => ({
  type: GET_APPLICATION_SUMMARY_SUCCESS,
  payload
});

export const getApplicationSummaryError = (payload) => ({
  type: GET_APPLICATION_SUMMARY_ERROR,
  payload
});

export const getBankList = (search, callback=null) => ({
  type: GET_BANK_LIST,
  payload: {search, callback}
});

export const getBankListSuccess = (payload) => ({
  type: GET_BANK_LIST_SUCCESS,
  payload
});

export const getBankListError = (payload) => ({
  type: GET_BANK_LIST_ERROR,
  payload
});

export const getCityList = (search, callback=null) => ({
  type: GET_CITY_LIST,
  payload: {search, callback}
});

export const getCityListSuccess = (payload) => ({
  type: GET_CITY_LIST_SUCCESS,
  payload: payload
});

export const getCityListError = (payload) => ({
  type: GET_CITY_LIST_ERROR,
  payload
});

export const sendSelfie = (payload, callback=null, next, steps, step) => ({
  type: SEND_SELFIE,
  payload,
  callback,
  next,
  steps,
  step
});

export const sendSelfieSuccess = (payload) => ({
  type: SEND_SELFIE_SUCCESS,
  payload
});

export const sendSelfieError = (payload) => ({
  type: SEND_SELFIE_ERROR,
  payload
});



