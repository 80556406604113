import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    ADD_MONEY_IN_DRIVER_WALLET,
    CREATE_FMS_USER,
    CREATE_NEW_CUSTOMER,
    CREATE_NEW_FMS_ROLE,
    CREATE_NEW_ORDER,
    GET_CHARGING_STATION_CITY_LIST,
    GET_CHARGING_STATION_LIST,
    GET_CUSTOMER_LIST,
    GET_DRIVER_LIST,
    GET_FMS_PERMISSIONS_LIST,
    GET_FMS_ROLE_LIST,
    GET_FMS_ROLE_PERMISSIONS,
    GET_FMS_USER_DETAILS,
    GET_FMS_USER_LIST,
    GET_FMS_VEHICLE_DETAILS,
    GET_FMS_VEHICLE_LIST,
    GET_ORDER_DETAILS,
    GET_ORDER_LIST,
    GET_ORDER_LIVE_ACTIVITY,
    GET_WALLET_DATA,
    UPDATE_FMS_USER_DETAILS,
    UPDATE_ORDER_DETAILS,
    UPDATE_ORDER_STATUS,
    UPDATE_ROLE_DETAILS,
    UPDATE_ROLE_PERMISSIONS,
} from '../contants';
import { Toaster } from 'components/toaster/Toaster';
import { FmsService } from 'service/fms';
import { 
    addMoneyInDriverWalletError,
    addMoneyInDriverWalletSuccess,
    createFMSRoleError,
    createFMSRoleSuccess,
    createFMSUserError,
    createFMSUserSuccess,
    createNewCustomerError,
    createNewCustomerSuccess,
    createNewOrderError,
    createNewOrderSuccess,
    getChargingStationCityError,
    getChargingStationCitySuccess,
    getChargingStationListError,
    getChargingStationListSuccess,
    getCustomerListError,
    getCustomerListSuccess,
    getDriverListError,
    getDriverListSuccess,
    getFMSPermissionsListError,
    getFMSPermissionsListSuccess,
    getFMSRolePermissionsListError,
    getFMSRolePermissionsListSuccess,
    getFMSRolesError,
    getFMSRolesSuccess,
    getFMSUserDetailsError,
    getFMSUserDetailsSuccess,
    getFMSUsersError,
    getFMSUsersSuccess,
    getFMSVehicleDetailsError,
    getFMSVehicleDetailsSuccess,
    getFMSVehicleListError,
    getFMSVehicleListSuccess,
    getOrderDetailsError,
    getOrderDetailsSuccess,
    getOrderListError,
    getOrderListSuccess, 
    getOrderLiveActivityError, 
    getWalletDataError,
    getWalletDataSuccess,
    updateFMSUserDetailsError,
    updateFMSUserDetailsSuccess,
    updateOrderDetailsError,
    updateOrderDetailsSuccess,
    updateOrderStatusError,
    updateOrderStatusSuccess,
    updateRoleDetailsError,
    updateRoleDetailsSuccess,
    updateRolePermissionsError,
    updateRolePermissionsSuccess
} from './action';



const server = new FmsService();
const toaster = new Toaster();

function* watchGetOrderList() {
  yield takeEvery(GET_ORDER_LIST, fetchOrderList);
}

function* watchCreateOrder() {
    yield takeEvery(CREATE_NEW_ORDER, createNewOrder);
}

function* watchOrderDetails() {
    yield takeEvery(GET_ORDER_DETAILS, fetchOrderDetails);
}

function* watchOrderStatusUpdate() {
    yield takeEvery(UPDATE_ORDER_STATUS, sendUpdatedOrderStatus);
}

function* watchOrderDetailsUpdate() {
    yield takeEvery(UPDATE_ORDER_DETAILS, sendUpdatedOrderDetails);
}

function* watchOrderLiveActivity() {
    yield takeEvery(GET_ORDER_LIVE_ACTIVITY, );
}

function* watchGetCustomerList() {
    yield takeEvery(GET_CUSTOMER_LIST, fetchCustomerList);
}

function* watchCreateCustomer() {
    yield takeEvery(CREATE_NEW_CUSTOMER, createNewCustomer);
}

function* watchDriverList() {
    yield takeEvery(GET_DRIVER_LIST, fetchDriverList);
}

function* watchFmsVehicleList() {
    yield takeEvery(GET_FMS_VEHICLE_LIST, fetchFMSVehicleList);
}

function* watchFmsVehicleDetails() {
    yield takeEvery(GET_FMS_VEHICLE_DETAILS, fetchFMSVehicleDetails);
}

function* watchChargingSttaionCityList() {
    yield takeEvery(GET_CHARGING_STATION_CITY_LIST, fetchChargingStationCityList);
}

function* watchChargingStationList() {
    yield takeEvery(GET_CHARGING_STATION_LIST, fetchChargingStationList);
}

function* watchWalletData() {
    yield takeEvery(GET_WALLET_DATA, fetchWalletData);
}

function* watchAddMoneyInDriverWallet() {
    yield takeEvery(ADD_MONEY_IN_DRIVER_WALLET, sendMoneyInWallet);
}

function* watchFMSUserCreation() {
    yield takeEvery(CREATE_FMS_USER, createNewFmsUser);
}

function* watchFMSUsers() {
    yield takeEvery(GET_FMS_USER_LIST, fetchFMSUsers);
}

function* watchFMSUserDetails() {
    yield takeEvery(GET_FMS_USER_DETAILS, fetchFMSUserDetails);
}

function* watchFMSRoles() {
    yield takeEvery(GET_FMS_ROLE_LIST, fetchFMSRoles);
}

function* watchFMSPermissions() {
    yield takeEvery(GET_FMS_PERMISSIONS_LIST, fetchFMSPermissionsList);
}

function* watchFMSRolePermissions() {
    yield takeEvery(GET_FMS_ROLE_PERMISSIONS, fetchFMSRolePermissionList);
}

function* watchCreateFMSRole() {
    yield takeEvery(CREATE_NEW_FMS_ROLE, sendNewFMSRole);
}

function* watchRoleUpdate() {
    yield takeEvery(UPDATE_ROLE_DETAILS, sendRoleDetails);
}

function* watchRolePermissionUpdate() {
    yield takeEvery(UPDATE_ROLE_PERMISSIONS, sendUpdatedRolePermissions);
}

function* watchFMSUserDetailsUpdate() {
    yield takeEvery(UPDATE_FMS_USER_DETAILS, sendUpdatedFMSUserDetails);
}





const fetchOrderListFromServer = async (payload) => (
  await server.getOrderList(payload).then((response) => (response)).catch((error) => (error))
);

const createNewOrderToServer = async (payload) => (
    await server.createNewOrder(payload).then((response) => (response)).catch((error) => (error))
);

const fetchOrderDetailsFromServer = async (payload) => (
    await server.getOrderDetails(payload).then((response) => (response)).catch((error) => (error))
);

const sendUpdatedOrderStatusToServer = async (payload) => (
    await server.updateOrderStatus(payload?.payload, payload?.id).then((response) => (response)).catch((error) => (error))
);

const sendUpdatedOrderDetailsToServer = async (payload) => (
    await server.updateOrderDetails(payload?.payload, payload?.id).then((response) => (response)).catch((error) => (error))
);

const fetchOrderLiveActivityFromServer = async (payload) => (
    await server.getOrderLiveActivity(payload).then((response) => (response)).catch((error) => (error))
)

const fetchCustomerListFromServer = async (payload) => (
    await server.getCustomerList(payload?.search).then((response) => (response)).catch((error) => (error))
);

const createNewCustomerToServer = async (payload) => (
    await server.createNewCustomer(payload).then((response) => (response)).catch((error) => (error))
);

const fetchDriverListFromServer = async (payload) => (
    await server.getDriverList(payload?.phoneNumber, payload?.name, payload?.licenseNumber).then((response) => (response)).catch((error) => (error))
);

const fetchFMSVehicleListFromServer = async (payload) => (
    await server.getVehicleList(payload).then((response) => (response)).catch((error) => (error))
);

const fetchFMSVehicleDetailsFromServer = async (payload) => (
    await server.getVehicleDetails(payload).then((response) => (response)).catch((error) => (error))
)

const fetchChargingStationCityListFromServer = async (payload) => (
    await server.getChargingStationCityList(payload).then((response) => (response)).catch((error) => (error))
);

const fetchChargingStationListFromServer = async (payload) => (
    await server.getChargingStationList(payload).then((response) => (response)).catch((error) => (error))
);

const fetchWalletDataFromServer = async (payload) => (
    await server.getWalletData(payload).then((response) => (response)).catch((error) => (error))
);

const sendTransactionToServer = async (payload) => (
    await server.sendTransactionData(payload).then((response) => (response)).catch((error) => (error))
);

const createNewFmsUserOnServer = async (payload) => (
    await server.createNewUser(payload).then((response) => (response)).catch((error) => (error))
);

const fetchFMSUsersFromServer = async (payload) => (
    await server.getFMSUsers(payload).then((response) => (response)).catch((error) => (error))
);

const fetchFMSUserDetailsFromServer = async (payload) => (
    await server.getFMSUserDetails(payload).then((response) => (response)).catch((error) => (error))
);

const fetchFMSRolesFromServer = async (payload) => (
    await server.getFMSRoles(payload).then((response) => (response)).catch((error) => (error))
);

const fetchFMSPermissionsListFromServer = async () => (
    await server.getFMSPermissions().then((response) => (response)).catch((error) => (error))
);

const fetchFMSRolePermissionListFromServer = async (payload) => (
    await server.getFMSRolePermissions(payload).then((response) => (response)).catch((error) => (error))
);

const sendNewFMSRoleToServer = async (payload) => (
    await server.createNewRole(payload).then((response) => (response)).catch((error) => (error))
);

const updateRoleDetailsToServer = async (payload) => (
    await server.updateRoleDetails(payload).then((response) => (response)).catch((error) => (error))
);

const sendUpdatedRolePermissionsToServer = async (payload) => (
    await server.updateRolePermissions(payload).then((response) => (response)).catch((error) => (error))
);

const sendUpdatedFMSUserDetailsToServer = async (payload) => (
    await server.updateFMSUserDetails(payload).then((response) => (response)).catch((error) => (error))
)





function* fetchOrderList({payload}) {
  try {
    const response = yield call(fetchOrderListFromServer, payload);
    if(response?.data?.success){
        yield put(getOrderListSuccess({data: response?.data?.data, total: response?.data?.count}));
    } else {
        throw new Error(response?.message);
    }
  } catch (error) {
        yield put(getOrderListError(error?.message));
        toaster.show(true, error?.message);
  }
}

function* createNewOrder({payload}) {
    try {
        const response = yield call(createNewOrderToServer, payload);
        if(response?.data?.success){
            yield put(createNewOrderSuccess(response?.data?.data));
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(createNewOrderError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchOrderDetails({payload}) {
    try {
        const response = yield call(fetchOrderDetailsFromServer, payload);
        if(response?.data?.success) {
            yield put(getOrderDetailsSuccess(response?.data?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getOrderDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* sendUpdatedOrderStatus({payload, id}) {
    try {
        const response = yield call(sendUpdatedOrderStatusToServer, {payload, id});
        if(response?.data?.success) {
            yield put(updateOrderStatusSuccess(response?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(updateOrderStatusError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* sendUpdatedOrderDetails({payload, id, callback}) {
    try {
        const response = yield call(sendUpdatedOrderDetailsToServer, {payload, id});
        if(response?.data?.success) {
            yield put(updateOrderDetailsSuccess(response?.data));
            if(callback) {
                callback();
            }
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(updateOrderDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchOrderLiveActivity({payload}) {
    try {
        const response = yield call(fetchOrderLiveActivityFromServer, {});
        if(response?.data?.success){
            
        }
    } catch(error) {
        yield put(getOrderLiveActivityError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchCustomerList({payload}) {
    try {
        const {search, callback} = payload;
        const response = yield call(fetchCustomerListFromServer, search);
        if(response?.data?.success){
            const data = response?.data?.data?.map((customer) => ({...customer, label: customer?.name, value: customer?.mobileNumber}));
            yield put(getCustomerListSuccess(data));
            if(callback){
                callback(data)
            }
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getCustomerListError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* createNewCustomer({payload}) {
    try {
        const response = yield call(createNewCustomerToServer, payload);
        if(response?.data?.success) {
            yield put(createNewCustomerSuccess(response?.data?.data));
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(createNewCustomerError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchDriverList({payload}){
    try {
        const {search, callback} = payload;
        const response = yield call(fetchDriverListFromServer, search);
        if(response?.data?.success){
            const data = response?.data?.data?.map((driver) => ({...driver, label: driver?.firstName, value: driver?.code}))
            yield put(getDriverListSuccess(data));
            if(callback){
                callback(data)
            }
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getDriverListError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchFMSVehicleList({payload, callback}) {
    try {
        const response = yield call(fetchFMSVehicleListFromServer, payload);
        if(response?.data?.success){
            const data = response?.data?.data?.map((vehicle) => ({...vehicle, label: vehicle?.vehicleNumber, value: vehicle?.code}))
            yield put(getFMSVehicleListSuccess({data, total: response?.data?.count}));
            if(callback){
                callback(data)
            }
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getFMSVehicleListError(error?.message));
        toaster.show(true, error?.message);
    }
} 

function* fetchFMSVehicleDetails({payload}) {
    try {
        const response = yield call(fetchFMSVehicleDetailsFromServer, payload);
        if(response?.data?.success){
            yield put(getFMSVehicleDetailsSuccess(response?.data?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getFMSVehicleDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}


function* fetchChargingStationCityList({payload}) {
    try {
        const {search, callback} = payload;
        const response = yield call(fetchChargingStationCityListFromServer, search);
        if(response?.data?.success){
            const data = response?.data?.data?.map((data) => ({...data, label: data?.cityName, value: data?.cityName}));
            yield put(getChargingStationCitySuccess(data))
            if(callback){
                callback(data);
            }
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
      yield put(getChargingStationCityError(error?.message));
      toaster.show(true, error?.message);
    }
}

function* fetchChargingStationList({payload}) {
    try {
        const response = yield call(fetchChargingStationListFromServer, payload);
        if(response?.data?.success){
            const data = response?.data?.data?.map((obj) => ({...obj, latitude: obj?.lat, longitude: obj?.lng}));
            yield put(getChargingStationListSuccess(data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getChargingStationListError(error?.payload));
        toaster.show(true, error?.message);
    }
}

function* fetchWalletData({payload}) {
    try {
        const response = yield call(fetchWalletDataFromServer, payload);
        if(response?.data?.success){
            yield put(getWalletDataSuccess({data: response?.data?.data, transaction: response?.data?.data?.transactions}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getWalletDataError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* sendMoneyInWallet({payload, callback}) {
    try {
        const response = yield call(sendTransactionToServer, payload);
        if(response?.data?.success){
            yield put(addMoneyInDriverWalletSuccess(response?.data));
            window.location.reload();
            if(callback){
                callback();
            }
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(addMoneyInDriverWalletError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* createNewFmsUser({payload, callback}) {
    try {
        const response = yield call(createNewFmsUserOnServer, payload);
        if(response?.data?.success) {
            toaster.show(false, 'User created successfully');
            yield put(createFMSUserSuccess({...payload, id: response?.data?.data}));
            if(callback){
                callback();
            }
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(createFMSUserError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchFMSUsers({payload}) {
    try {
        const response = yield call(fetchFMSUsersFromServer, payload);
        if(response?.data?.success) {
            yield put(getFMSUsersSuccess({list: response?.data?.data, total: response?.data?.count}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getFMSUsersError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchFMSUserDetails({payload}) {
    try {
        const response = yield call(fetchFMSUserDetailsFromServer, payload);
        if(response?.data?.success){
            yield put(getFMSUserDetailsSuccess(response?.data?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getFMSUserDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchFMSRoles({payload}) {
    try {
        const response = yield call(fetchFMSRolesFromServer, payload);
        if(response?.data?.success) {
            yield put(getFMSRolesSuccess({list: response?.data?.roles, total: response?.data?.count}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getFMSRolesError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchFMSPermissionsList() {
    try {
        const response = yield call(fetchFMSPermissionsListFromServer, {});
        if(response?.data?.success){
            yield put(getFMSPermissionsListSuccess(response?.data?.permissions));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getFMSPermissionsListError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchFMSRolePermissionList({payload}) {
    try { 
        const response = yield call(fetchFMSRolePermissionListFromServer, payload);
        if(response?.data?.success) {
            yield put(getFMSRolePermissionsListSuccess({roleInfo: response?.data?.roleInfo, permissions: response?.data?.permissions}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getFMSRolePermissionsListError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* sendNewFMSRole({payload, callback}) {
    try {
        const response = yield call(sendNewFMSRoleToServer, payload);
        if(response?.data?.success) {
            toaster.show(false, 'Role created successfully');
            yield put(createFMSRoleSuccess({...payload, createDate: new Date(), roleId: response?.data?.roleId}));
            if(callback){
                callback();
            }
        } else {
            throw new Error(response?.message);
        } 
    } catch(error) {
        yield put(createFMSRoleError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* sendRoleDetails({payload}) {
    try {
        console.log(payload, 'payload in saga');
        const response = yield call(updateRoleDetailsToServer, payload);
        if(response?.data?.success) {
            yield put(updateRoleDetailsSuccess());
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(updateRoleDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* sendUpdatedRolePermissions({payload}) {
    try {
        const response = yield call(sendUpdatedRolePermissionsToServer, payload);
        if(response?.data?.success){
            toaster.show(false, 'Permissions updated successfully');
            yield put(updateRolePermissionsSuccess(payload));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(updateRolePermissionsError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* sendUpdatedFMSUserDetails({payload}) {
    try {
        const response = yield call(sendUpdatedFMSUserDetailsToServer, payload);
        if(response?.data?.success){
            yield put(updateFMSUserDetailsSuccess(payload));
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(updateFMSUserDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}



export default function* fmsSaga() {
  yield all([
    fork(watchGetOrderList),
    fork(watchCreateOrder),
    fork(watchOrderDetails),
    fork(watchOrderStatusUpdate),
    fork(watchOrderDetailsUpdate),
    fork(watchGetCustomerList),
    fork(watchCreateCustomer),
    fork(watchDriverList),
    fork(watchFmsVehicleList),
    fork(watchFmsVehicleDetails),
    fork(watchChargingSttaionCityList),
    fork(watchChargingStationList),
    fork(watchWalletData),
    fork(watchAddMoneyInDriverWallet),
    fork(watchFMSUserCreation),
    fork(watchFMSUsers),
    fork(watchFMSUserDetails),
    fork(watchFMSRoles),
    fork(watchFMSPermissions),
    fork(watchFMSRolePermissions),
    fork(watchCreateFMSRole),
    fork(watchRoleUpdate),
    fork(watchRolePermissionUpdate),
    fork(watchFMSUserDetailsUpdate)
  ]);
}
