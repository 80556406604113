import { axiosInstance } from "./base";
import { headers } from "./base";

export class FmsService {
  COS_BASE_URL = process.env.REACT_APP_COS_BASE_URL;

  getDriverList = (phoneNumber='', name='', licenseNumber='') => {
    return axiosInstance.get(`${this.COS_BASE_URL}/drivers?name=${name}&phoneNumber=${phoneNumber}&licenseNumber=${licenseNumber}`, {headers: headers()});
  };

  getPermissionList = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/user-role`, {headers: headers()});
  };

  getVehicleList = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicle-entries?page=${payload?.pagination?.page || 1}&perPage=${payload?.pagination?.perPage || 20}&fromDate=${payload?.dateRange?.startDate || ''}&toDate=${payload?.dateRange?.endDate || ''}&search=${payload?.search || ''}`, {headers: headers()});
  };

  getVehicleDetails = (id) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicle-entries/${id}`, {headers: headers()});
  }

  getIssueTypeList = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/issue-categories`, {headers: headers()});
  };

  getIssueCategoryByIssueTypeCodeList = (code) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/issue-subcategories/code/${code}`, {headers: headers()});
  };

  getAllIssueListByOrg = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/issues-org`, {headers: headers()});
  };

  createIssue = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/issues`, payload,{headers: headers()});
  };

  closeIssue = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/issues-close`, payload,{headers: headers()});
  };

  getIssuesbyOrgList = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/issues-org/${payload}`, {headers: headers()});
  };

  getIssueDetails = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/issues-detail/${payload}`, {headers: headers()});
  };

  createVehicle = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/vehicle-entries`, payload,{headers: headers()});
  };

  deleteVehicle = (payload) => {
    return axiosInstance.delete(`${this.COS_BASE_URL}/vehicle-entries/${payload}`, {headers: headers()});
  };

  assignEngineer = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/issues-request`, payload,{headers: headers()});
  };

  
  getVehicleModelList = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicle-model`, {headers: headers()});
  };

  createDriver = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/drivers`, payload, {headers: headers()});
  };

  deleteDriver = (payload) => {
    return axiosInstance.delete(`${this.COS_BASE_URL}/drivers/${payload}`, {headers: headers()});
  };

  getDriverListByOrg = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/drivers`, {headers: headers()});
  };


  // Order
  getOrderList = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/order?page=${payload?.pagination?.page}&perPage=${payload?.pagination?.perPage}&fromDate=${payload?.dateRange?.startDate || ''}&toDate=${payload?.dateRange?.endDate || ''}&search=${payload?.search}`, {headers: headers()});
  }

  getOrderDetails = (id) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/order/${id}`, {headers: headers()});
  }

  createNewOrder = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/order`, payload, {headers: headers()});
  }

  updateOrderStatus = (payload, id) => {
    return axiosInstance.patch(`${this.COS_BASE_URL}/order/${id}`, payload, {headers: headers()});
  }

  updateOrderDetails = (payload, id) => {
    return axiosInstance.patch(`${this.COS_BASE_URL}/order/data/${id}`, payload, {headers: headers()});
  }

  getOrderLiveActivity = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/`, {headers: headers()});
  }


  //Customers
  createNewCustomer = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/fms/customer`, payload, {headers: headers()});
  }

  getCustomerList = (search='') => {
    return axiosInstance.get(`${this.COS_BASE_URL}/fms/customer?search=${search}`, {headers: headers()});
  }

  //Charging station
  getChargingStationCityList = (name='') => {
    return axiosInstance.get(`${this.COS_BASE_URL}/charging-station-cities?search=${name}`, {headers: headers()});
  };

  getChargingStationList = (name='') => {
    return axiosInstance.get(`${this.COS_BASE_URL}/charging-station?search=${name}`, {headers: headers()});
  };

  //Wallet
  getWalletData = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/wallet?page=${payload?.pagination?.page}&perPage=${payload?.pagination?.perPage}&fromDate=${payload?.dateRange?.startDate || ''}&toDate=${payload?.dateRange?.endDate || ''}&search=${payload?.status}`, {headers: headers()});
  }

  sendTransactionData = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/wallet/transaction`, payload, {headers: headers()});
  }

  //User Management
  createNewUser = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/create-user`, payload, {headers: headers()});
  }

  getFMSUsers = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/users?page=${payload?.pagination?.page}&perPage=${payload?.pagination?.perPage}&search=${payload?.search}&fromDate=${payload?.dateRange?.startDate}&toDate=${payload?.dateRange?.endDate}`, {headers: headers()});
  }

  getFMSUserDetails = (id) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/user/${id}`, {headers: headers()});
  }

  updateFMSUserDetails = (payload) => {
    return axiosInstance.patch(`${this.COS_BASE_URL}/user/${payload?.id}`, payload, {headers: headers()});
  }

  //Roles
  getFMSRoles = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/roles?page=${payload?.pagination?.page}&perPage=${payload?.pagination?.perPage}&search=${payload?.search}`, {headers: headers()});
  }

  getFMSPermissions = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/permissions`, {headers: headers()});
  }

  getFMSRolePermissions = (id) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/get-role/${id}`, {headers: headers()});
  }

  createNewRole = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/create-role`, payload, {headers: headers()});
  }

  updateRoleDetails = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/update-role`, payload, {headers: headers()});
  }

  updateRolePermissions = (payload) => {
    console.log(payload, 'tetsing payload')
    return axiosInstance.post(`${this.COS_BASE_URL}/update-permission`, payload, {headers: headers()});
  }

} 

