import { 
    GET_CONSOLIDATED_DISTANCE_AND_CO2,
    GET_CONSOLIDATED_DISTANCE_AND_CO2_ERROR,
    GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE,
    GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_ERROR,
    GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_SUCCESS,
    GET_CONSOLIDATED_DISTANCE_AND_CO2_SUCCESS,
    GET_CONSOLIDATED_LOCATION,
    GET_CONSOLIDATED_LOCATION_ERROR,
    GET_CONSOLIDATED_LOCATION_SUCCESS,
    GET_CONSOLIDATED_VEHICLE_DATA,
    GET_CONSOLIDATED_VEHICLE_DATA_ERROR,
    GET_CONSOLIDATED_VEHICLE_DATA_SUCCESS,
    GET_GLOBAL_EV_DATA,
    GET_GLOBAL_EV_DATA_ERROR,
    GET_GLOBAL_EV_DATA_SUCCESS,
    GET_INDIVIDUAL_VEHICLE_DATA,
    GET_INDIVIDUAL_VEHICLE_DATA_ERROR,
    GET_INDIVIDUAL_VEHICLE_DATA_SUCCESS,
    GET_PARTNER_LIST,
    GET_PARTNER_LIST_ERROR,
    GET_PARTNER_LIST_SUCCESS,
    GET_SPECIFICDATA_ON_KEY,
    GET_VEHICLE_DATA,
    GET_VEHICLE_DATA_ERROR,
    GET_VEHICLE_DATA_SUCCESS,
    GET_VEHICLE_DRIVER_AND_ORDER_DETAILS,
    GET_VEHICLE_DRIVER_AND_ORDER_DETAILS_ERROR,
    GET_VEHICLE_DRIVER_AND_ORDER_DETAILS_SUCCESS,
    GET_VEHICLE_LIST,
    GET_VEHICLE_LIST_ERROR,
    GET_VEHICLE_LIST_SUCCESS,
    VEHICLE_DETAILS, 
    VEHICLE_LIST
} from "redux/contants";

export const getVehicleData = (action, payload) => ({
    type: GET_VEHICLE_DATA,
    payload: {action: action, payload: payload}
});

export const getVehicleDataSuccess = (action, payload) => ({
    type: GET_VEHICLE_DATA_SUCCESS,
    payload
});

export const getVehicleDataError = (action, payload) => ({
    type: GET_VEHICLE_DATA_ERROR,
    payload
});

export const saveOEMData = (action, payload) => ({
    type: action,
    payload
});

export const saveVehicleData = (payload) => ({
    type: VEHICLE_DETAILS,
    payload
});

export const updateVehicleList = (payload) => ({
    type: VEHICLE_LIST,
    payload
});

export const getGlobalEVdata = (payload) => ({
    type: GET_GLOBAL_EV_DATA,
    payload
});

export const getGlobalEVdataSuccess = (payload) => ({
    type: GET_GLOBAL_EV_DATA_SUCCESS,
    payload
});

export const getGlobalEVdataError = (payload) => ({
    type: GET_GLOBAL_EV_DATA_ERROR,
    payload
});

export const getConsolidatedLocation = (payload) => ({
    type: GET_CONSOLIDATED_LOCATION,
    payload
});

export const getConsolidatedLocationSuccess = (payload) => ({
    type: GET_CONSOLIDATED_LOCATION_SUCCESS,
    payload
});

export const getConsolidatedLocationError = (payload) => ({
    type: GET_CONSOLIDATED_LOCATION_ERROR,
    payload
})

export const getConsolidatedVehicleData = (payload) => ({
    type: GET_CONSOLIDATED_VEHICLE_DATA,
    payload
});

export const getConsolidatedVehicleDataSuccess = (data) => ({
    type: GET_CONSOLIDATED_VEHICLE_DATA_SUCCESS,
    payload: data
});

export const getConsolidatedVehicleDataError = (key, error) => ({
    type: GET_CONSOLIDATED_VEHICLE_DATA_ERROR,
    payload: {key, error}
});

export const getConsolidatedDistanceAndCo2 = (payload, key=null) => ({
    type: GET_CONSOLIDATED_DISTANCE_AND_CO2,
    payload: {payload, key}
});

export const getConsolidatedDistanceAndCo2Success = (payload) => ({
    type: GET_CONSOLIDATED_DISTANCE_AND_CO2_SUCCESS,
    payload
});

export const getConsolidatedDistanceAndCo2Error = (payload) => ({
    type: GET_CONSOLIDATED_DISTANCE_AND_CO2_ERROR,
    payload
});

export const getConsolidatedDistanceAndCo2OnDate = (payload) => ({
    type: GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE,
    payload
});

export const getConsolidatedDistanceAndCo2OnDateSuccess = (payload) => ({
    type: GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_SUCCESS,
    payload
});

export const getConsolidatedDistanceAndCo2OnDateError = (payalod) => ({
    type: GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_ERROR,
    payalod
});

export const getVehicleList = (payload) => ({
    type: GET_VEHICLE_LIST,
    payload
});

export const getVehicleListSuccess = (payload) => ({
    type: GET_VEHICLE_LIST_SUCCESS,
    payload
});

export const getVehicleListError = (payload) => ({
    type: GET_VEHICLE_LIST_ERROR,
    payload
})

export const getIndividualVehicleData = (payload) => ({
    type: GET_INDIVIDUAL_VEHICLE_DATA,
    payload
});

export const getIndividualVehicleDataSuccess = (payload) => ({
    type: GET_INDIVIDUAL_VEHICLE_DATA_SUCCESS,
    payload
});

export const getIndividualVehicleDataError = (payload) => ({
    type: GET_INDIVIDUAL_VEHICLE_DATA_ERROR,
    payload
});

export const getPartnerList = (payload) => ({
    type: GET_PARTNER_LIST,
    payload
});

export const getPartnerListSuccess = (payload) => ({
    type: GET_PARTNER_LIST_SUCCESS,
    payload
});

export const getPartnerListError = (payload) => ({
    type: GET_PARTNER_LIST_ERROR,
    payload
});

export const getVehicleDriverAndOrderDetails = (payload) => ({
    type: GET_VEHICLE_DRIVER_AND_ORDER_DETAILS,
    payload
});

export const getVehicleDriverAndOrderDetailsSuccess = (payload) => ({
    type: GET_VEHICLE_DRIVER_AND_ORDER_DETAILS_SUCCESS,
    payload
});

export const getVehicleDriverAndOrderDetailsError = (payload) => ({
    type: GET_VEHICLE_DRIVER_AND_ORDER_DETAILS_ERROR,
    payload
});
 