import { 
    ADD_MONEY_IN_DRIVER_WALLET,
    ADD_MONEY_IN_DRIVER_WALLET_ERROR,
    ADD_MONEY_IN_DRIVER_WALLET_SUCCESS,
    CREATE_FMS_USER,
    CREATE_FMS_USER_ERROR,
    CREATE_FMS_USER_SUCCESS,
    CREATE_NEW_CUSTOMER,
    CREATE_NEW_CUSTOMER_ERROR,
    CREATE_NEW_CUSTOMER_SUCCESS,
    CREATE_NEW_FMS_ROLE,
    CREATE_NEW_FMS_ROLE_ERROR,
    CREATE_NEW_FMS_ROLE_SUCCESS,
    CREATE_NEW_ORDER,
    CREATE_NEW_ORDER_ERROR,
    CREATE_NEW_ORDER_SUCCESS,
    GET_CHARGING_STATION_CITY_LIST,
    GET_CHARGING_STATION_CITY_LIST_ERROR,
    GET_CHARGING_STATION_CITY_LIST_SUCCESS,
    GET_CHARGING_STATION_LIST,
    GET_CHARGING_STATION_LIST_ERROR,
    GET_CHARGING_STATION_LIST_SUCCESS,
    GET_CUSTOMER_LIST,
    GET_CUSTOMER_LIST_ERROR,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_DRIVER_LIST,
    GET_DRIVER_LIST_ERROR,
    GET_DRIVER_LIST_SUCCESS,
    GET_FMS_PERMISSIONS_LIST,
    GET_FMS_PERMISSIONS_LIST_ERROR,
    GET_FMS_PERMISSIONS_LIST_SUCCESS,
    GET_FMS_ROLE_LIST,
    GET_FMS_ROLE_LIST_ERROR,
    GET_FMS_ROLE_LIST_SUCCESS,
    GET_FMS_ROLE_PERMISSIONS,
    GET_FMS_ROLE_PERMISSIONS_ERROR,
    GET_FMS_ROLE_PERMISSIONS_SUCCESS,
    GET_FMS_USER_DETAILS,
    GET_FMS_USER_DETAILS_ERROR,
    GET_FMS_USER_DETAILS_SUCCESS,
    GET_FMS_USER_LIST,
    GET_FMS_USER_LIST_ERROR,
    GET_FMS_USER_LIST_SUCCESS,
    GET_FMS_VEHICLE_DETAILS,
    GET_FMS_VEHICLE_DETAILS_ERROR,
    GET_FMS_VEHICLE_DETAILS_SUCCESS,
    GET_FMS_VEHICLE_LIST,
    GET_FMS_VEHICLE_LIST_ERROR,
    GET_FMS_VEHICLE_LIST_SUCCESS,
    GET_ORDER_DETAILS,
    GET_ORDER_DETAILS_ERROR,
    GET_ORDER_DETAILS_SUCCESS,
    GET_ORDER_LIST, 
    GET_ORDER_LIST_ERROR, 
    GET_ORDER_LIST_SUCCESS, 
    GET_ORDER_LIVE_ACTIVITY, 
    GET_ORDER_LIVE_ACTIVITY_ERROR, 
    GET_ORDER_LIVE_ACTIVITY_SUCCESS, 
    GET_WALLET_DATA, 
    GET_WALLET_DATA_ERROR, 
    GET_WALLET_DATA_SUCCESS, 
    PICKUP_AND_DROP,
    UPDATE_FMS_USER_DETAILS,
    UPDATE_FMS_USER_DETAILS_ERROR,
    UPDATE_FMS_USER_DETAILS_SUCCESS,
    UPDATE_ORDER_DETAILS,
    UPDATE_ORDER_DETAILS_ERROR,
    UPDATE_ORDER_DETAILS_SUCCESS,
    UPDATE_ORDER_STATUS,
    UPDATE_ORDER_STATUS_ERROR,
    UPDATE_ORDER_STATUS_SUCCESS,
    UPDATE_ROLE_DETAILS,
    UPDATE_ROLE_DETAILS_ERROR,
    UPDATE_ROLE_DETAILS_SUCCESS,
    UPDATE_ROLE_PERMISSIONS,
    UPDATE_ROLE_PERMISSIONS_ERROR,
    UPDATE_ROLE_PERMISSIONS_SUCCESS
} from "redux/contants";


export const getOrderList = (payload) => ({
    type: GET_ORDER_LIST,
    payload
});

export const getOrderListSuccess = (payload) => ({
    type: GET_ORDER_LIST_SUCCESS,
    payload
});

export const getOrderListError = (payload) => ({
    type: GET_ORDER_LIST_ERROR,
    payload
});

export const getOrderDetails = (payload) => ({
    type: GET_ORDER_DETAILS,
    payload
});

export const getOrderDetailsSuccess = (payload) => ({
    type: GET_ORDER_DETAILS_SUCCESS,
    payload
});

export const getOrderDetailsError = (payload) => ({
    type: GET_ORDER_DETAILS_ERROR,
    payload
});

export const updateOrderStatus = (payload, id) => ({
    type: UPDATE_ORDER_STATUS,
    payload,
    id
});

export const updateOrderStatusSuccess = (payload) => ({
    type: UPDATE_ORDER_STATUS_SUCCESS,
    payload
});

export const updateOrderStatusError = (payload) => ({
    type: UPDATE_ORDER_STATUS_ERROR,
    payload
});

export const updateOrderDetails = (payload, id, callback) => ({
    type: UPDATE_ORDER_DETAILS,
    payload,
    id,
    callback
});

export const updateOrderDetailsSuccess = (payload) => ({
    type: UPDATE_ORDER_DETAILS_SUCCESS,
    payload
});

export const updateOrderDetailsError = (payload) => ({
    type: UPDATE_ORDER_DETAILS_ERROR,
    payload
});

export const updatePickupAndDrop = (payload) => ({
    type: PICKUP_AND_DROP,
    payload
});

export const createNewOrder = (payload) => ({
    type: CREATE_NEW_ORDER,
    payload
});

export const createNewOrderSuccess = (payload) => ({
    type: CREATE_NEW_ORDER_SUCCESS,
    payload
});

export const createNewOrderError = (payload) => ({
    type: CREATE_NEW_ORDER_ERROR,
    payload
});

export const getOrderLiveActivity = (payload) => ({
    type: GET_ORDER_LIVE_ACTIVITY,
    payload
});

export const getOrderLiveActivitySuccess = (payload) => ({
    type: GET_ORDER_LIVE_ACTIVITY_SUCCESS,
    payload
});

export const getOrderLiveActivityError = (payload) => ({
    type: GET_ORDER_LIVE_ACTIVITY_ERROR,
    payload
});

export const createNewCustomer = (payload) => ({
    type: CREATE_NEW_CUSTOMER,
    payload
});

export const createNewCustomerSuccess = (payload) => ({
    type: CREATE_NEW_CUSTOMER_SUCCESS,
    payload
});

export const createNewCustomerError = (payload) => ({
    type: CREATE_NEW_CUSTOMER_ERROR,
    payload
});

export const getCustomerList = (search='', callback=null) => ({
    type: GET_CUSTOMER_LIST,
    payload: {search, callback}
});

export const getCustomerListSuccess = (payload) => ({
    type: GET_CUSTOMER_LIST_SUCCESS,
    payload
});

export const getCustomerListError = (payload) => ({
    type: GET_CUSTOMER_LIST_ERROR,
    payload
});

export const getDriverList = (search='', callback=null) => ({
    type: GET_DRIVER_LIST,
    payload: {search, callback}
});

export const getDriverListSuccess = (payload) => ({
    type: GET_DRIVER_LIST_SUCCESS,
    payload
});

export const getDriverListError = (payload) => ({
    type: GET_DRIVER_LIST_ERROR,
    payload
});

export const getFMSVehicleList = (payload, callback=null) => ({
    type: GET_FMS_VEHICLE_LIST,
    payload,
    callback
});

export const getFMSVehicleListSuccess = (payload) => ({
    type: GET_FMS_VEHICLE_LIST_SUCCESS,
    payload
});

export const getFMSVehicleListError = (payload) => ({
    type: GET_FMS_VEHICLE_LIST_ERROR,
    payload
});

export const getFMSVehicleDetails = (payload) => ({
    type: GET_FMS_VEHICLE_DETAILS,
    payload
});

export const getFMSVehicleDetailsSuccess = (payload) => ({
    type: GET_FMS_VEHICLE_DETAILS_SUCCESS,
    payload
});

export const getFMSVehicleDetailsError = (payload) => ({
    type: GET_FMS_VEHICLE_DETAILS_ERROR,
    payload
});

export const getChargingStationCity = (search, callback=null) => ({
    type: GET_CHARGING_STATION_CITY_LIST,
    payload: {search, callback}
});
  
export const getChargingStationCitySuccess = (payload) => ({
    type: GET_CHARGING_STATION_CITY_LIST_SUCCESS,
    payload: payload
});
  
export const getChargingStationCityError = (payload) => ({
    type: GET_CHARGING_STATION_CITY_LIST_ERROR,
    payload
});

export const getChargingStationList = (payload) => ({
    type: GET_CHARGING_STATION_LIST,
    payload
});

export const getChargingStationListSuccess = (payload) => ({
    type: GET_CHARGING_STATION_LIST_SUCCESS,
    payload
});

export const getChargingStationListError = (payload) => ({
    type: GET_CHARGING_STATION_LIST_ERROR,
    payload
});

export const getWalletData = (payload) => ({
    type: GET_WALLET_DATA,
    payload
});

export const getWalletDataSuccess = (payload) => ({
    type: GET_WALLET_DATA_SUCCESS,
    payload
});

export const getWalletDataError = (payload) => ({
    type: GET_WALLET_DATA_ERROR,
    payload
});

export const addMoneyInDriverWallet = (payload, callback=null) => ({
    type: ADD_MONEY_IN_DRIVER_WALLET,
    payload,
    callback
});

export const addMoneyInDriverWalletSuccess = (payload) => ({
    type: ADD_MONEY_IN_DRIVER_WALLET_SUCCESS,
    payload
});

export const addMoneyInDriverWalletError = (payload) => ({
    type: ADD_MONEY_IN_DRIVER_WALLET_ERROR,
    payload
});

export const createFMSUser = (payload, callback=null) => ({
    type: CREATE_FMS_USER,
    payload,
    callback
});

export const createFMSUserSuccess = (payload) => ({
    type: CREATE_FMS_USER_SUCCESS,
    payload
});

export const createFMSUserError = (payload) => ({
    type: CREATE_FMS_USER_ERROR,
    payload
});

export const getFMSUsers = (payload) => ({
    type: GET_FMS_USER_LIST,
    payload
});

export const getFMSUsersSuccess = (payload) => ({
    type: GET_FMS_USER_LIST_SUCCESS,
    payload
});

export const getFMSUsersError = (payload) => ({
    type: GET_FMS_USER_LIST_ERROR,
    payload
});

export const getFMSUserDetails = (payload) => ({
    type: GET_FMS_USER_DETAILS,
    payload
});

export const getFMSUserDetailsSuccess = (payload) => ({
    type: GET_FMS_USER_DETAILS_SUCCESS,
    payload
});

export const getFMSUserDetailsError = (payload) => ({
    type: GET_FMS_USER_DETAILS_ERROR,
    payload
});

export const updateFMSUserDetails = (payload) => ({
    type: UPDATE_FMS_USER_DETAILS,
    payload
});

export const updateFMSUserDetailsSuccess = (payload) => ({
    type: UPDATE_FMS_USER_DETAILS_SUCCESS,
    payload
});

export const updateFMSUserDetailsError = (payload) => ({
    type: UPDATE_FMS_USER_DETAILS_ERROR,
    payload
});

export const getFMSRoles = (payload) => ({
    type: GET_FMS_ROLE_LIST,
    payload
});

export const getFMSRolesSuccess = (payload) => ({
    type: GET_FMS_ROLE_LIST_SUCCESS,
    payload
});

export const getFMSRolesError = (payload) => ({
    type: GET_FMS_ROLE_LIST_ERROR,
    payload
});

export const getFMSPermissionsList = () => ({
    type: GET_FMS_PERMISSIONS_LIST
});

export const getFMSPermissionsListSuccess = (payload) => ({
    type: GET_FMS_PERMISSIONS_LIST_SUCCESS,
    payload
});

export const getFMSPermissionsListError = (payload) => ({
    type: GET_FMS_PERMISSIONS_LIST_ERROR,
    payload
});

export const getFMSRolePermissionsList = (payload) => ({
    type: GET_FMS_ROLE_PERMISSIONS,
    payload
});

export const getFMSRolePermissionsListSuccess = (payload) => {
    const {roleInfo, permissions} = payload
    let result = {};
    for(const module of permissions){
        let obj = {};
        for(const subModule of module?.subModules){
            obj = {
                ...obj,
                [subModule.subModuleCode]: subModule.permissions
            }
        }
        result = {
            ...result,
            [module.moduleCode]: obj
        }
    }
    return {
        type: GET_FMS_ROLE_PERMISSIONS_SUCCESS,
        payload: {roleInfo, permissions: result}
    }
};

export const getFMSRolePermissionsListError = (payload) => ({
    type: GET_FMS_ROLE_PERMISSIONS_ERROR,
    payload
});

export const createFMSRole = (payload, callback=null) => ({
    type: CREATE_NEW_FMS_ROLE,
    payload,
    callback
});

export const createFMSRoleSuccess = (payload) => ({
    type: CREATE_NEW_FMS_ROLE_SUCCESS,
    payload
});

export const createFMSRoleError = (payload) => ({
    type: CREATE_NEW_FMS_ROLE_ERROR,
    payload
});

export const updateRoleDetails = (payload) => ({
    type: UPDATE_ROLE_DETAILS,
    payload
});

export const updateRoleDetailsSuccess = (payload) => ({
    type: UPDATE_ROLE_DETAILS_SUCCESS,
    payload
});

export const updateRoleDetailsError = (payload) => ({
    type: UPDATE_ROLE_DETAILS_ERROR,
    payload
});

export const updateRolePermissions = (payload) => ({
    type: UPDATE_ROLE_PERMISSIONS,
    payload
});

export const updateRolePermissionsSuccess = (payload) => ({
    type: UPDATE_ROLE_PERMISSIONS_SUCCESS,
    payload
});

export const updateRolePermissionsError = (payload) => ({
    type: UPDATE_ROLE_PERMISSIONS_ERROR,
    payload
});

 
  