import { 
    ADD_MONEY_IN_DRIVER_WALLET,
    ADD_MONEY_IN_DRIVER_WALLET_ERROR,
    ADD_MONEY_IN_DRIVER_WALLET_SUCCESS,
    CREATE_FMS_USER,
    CREATE_FMS_USER_ERROR,
    CREATE_FMS_USER_SUCCESS,
    CREATE_NEW_CUSTOMER,
    CREATE_NEW_CUSTOMER_ERROR,
    CREATE_NEW_CUSTOMER_SUCCESS,
    CREATE_NEW_FMS_ROLE,
    CREATE_NEW_FMS_ROLE_ERROR,
    CREATE_NEW_FMS_ROLE_SUCCESS,
    CREATE_NEW_ORDER,
    CREATE_NEW_ORDER_ERROR,
    CREATE_NEW_ORDER_SUCCESS,
    GET_CHARGING_STATION_CITY_LIST,
    GET_CHARGING_STATION_CITY_LIST_ERROR,
    GET_CHARGING_STATION_CITY_LIST_SUCCESS,
    GET_CHARGING_STATION_LIST,
    GET_CHARGING_STATION_LIST_ERROR,
    GET_CHARGING_STATION_LIST_SUCCESS,
    GET_CUSTOMER_LIST,
    GET_CUSTOMER_LIST_ERROR,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_DRIVER_LIST,
    GET_DRIVER_LIST_ERROR,
    GET_DRIVER_LIST_SUCCESS,
    GET_FMS_PERMISSIONS_LIST,
    GET_FMS_PERMISSIONS_LIST_ERROR,
    GET_FMS_PERMISSIONS_LIST_SUCCESS,
    GET_FMS_ROLE_LIST,
    GET_FMS_ROLE_LIST_ERROR,
    GET_FMS_ROLE_LIST_SUCCESS,
    GET_FMS_ROLE_PERMISSIONS,
    GET_FMS_ROLE_PERMISSIONS_ERROR,
    GET_FMS_ROLE_PERMISSIONS_SUCCESS,
    GET_FMS_USER_DETAILS,
    GET_FMS_USER_DETAILS_ERROR,
    GET_FMS_USER_DETAILS_SUCCESS,
    GET_FMS_USER_LIST,
    GET_FMS_USER_LIST_ERROR,
    GET_FMS_USER_LIST_SUCCESS,
    GET_FMS_VEHICLE_DETAILS,
    GET_FMS_VEHICLE_DETAILS_ERROR,
    GET_FMS_VEHICLE_DETAILS_SUCCESS,
    GET_FMS_VEHICLE_LIST,
    GET_FMS_VEHICLE_LIST_ERROR,
    GET_FMS_VEHICLE_LIST_SUCCESS,
    GET_ORDER_DETAILS,
    GET_ORDER_DETAILS_ERROR,
    GET_ORDER_DETAILS_SUCCESS,
    GET_ORDER_LIST, 
    GET_ORDER_LIST_ERROR, 
    GET_ORDER_LIST_SUCCESS,
    GET_ORDER_LIVE_ACTIVITY,
    GET_ORDER_LIVE_ACTIVITY_ERROR,
    GET_ORDER_LIVE_ACTIVITY_SUCCESS,
    GET_WALLET_DATA,
    GET_WALLET_DATA_ERROR,
    GET_WALLET_DATA_SUCCESS,
    PICKUP_AND_DROP,
    UPDATE_FMS_USER_DETAILS,
    UPDATE_FMS_USER_DETAILS_ERROR,
    UPDATE_FMS_USER_DETAILS_SUCCESS,
    UPDATE_ORDER_DETAILS,
    UPDATE_ORDER_DETAILS_ERROR,
    UPDATE_ORDER_DETAILS_SUCCESS,
    UPDATE_ORDER_STATUS,
    UPDATE_ORDER_STATUS_ERROR,
    UPDATE_ORDER_STATUS_SUCCESS,
    UPDATE_ROLE_DETAILS,
    UPDATE_ROLE_DETAILS_ERROR,
    UPDATE_ROLE_DETAILS_SUCCESS,
    UPDATE_ROLE_PERMISSIONS,
    UPDATE_ROLE_PERMISSIONS_ERROR,
    UPDATE_ROLE_PERMISSIONS_SUCCESS
} from "redux/contants";

const orderData = {
    loading: false,
    error: null,
    list: [],
    details: {},
    pickupAndDrop: {},
    total: 0
};
  
export const fmsOrderReducer = (state=orderData, action) => {
    switch(action?.type){
        case GET_ORDER_LIST:
            return {...state, loading: true, error: null, list: []};
        case GET_ORDER_LIST_SUCCESS:
            return {...state, loading: false, list: action?.payload?.data, total: action?.payload?.total};
        case GET_ORDER_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        case GET_ORDER_DETAILS:
            return {...state, loading: true, details: {}, error: null};
        case GET_ORDER_DETAILS_SUCCESS:
            return {...state, loading: false, details: action?.payload};
        case GET_ORDER_DETAILS_ERROR:
            return {...state, loading: false, error: action?.payload};
        case PICKUP_AND_DROP:
            return {...state, pickupAndDrop: action?.payload};
        case CREATE_NEW_ORDER:
            return {...state, error: null,loading: true};
        case CREATE_NEW_ORDER_SUCCESS:
            return {...state, error: null, loading: false};
        case CREATE_NEW_ORDER_ERROR:
            return {...state, error: action?.payload, loading: false};
        case UPDATE_ORDER_STATUS:
            return {...state, loading: true, error: null};
        case UPDATE_ORDER_STATUS_SUCCESS:
            return {...state, loading: false};
        case UPDATE_ORDER_STATUS_ERROR:
            return {...state, loading: false, error: action?.payload};
        case UPDATE_ORDER_DETAILS:
            return {...state, loading: true, error: null};
        case UPDATE_ORDER_DETAILS_SUCCESS:
            return {...state, loading: false};
        case UPDATE_ORDER_DETAILS_ERROR:
            return {...state, loading: false, error: action?.payload};
        default:
            return state;
    }
}

const orderActivity = {
    loading: false,
    error: null,
    location: null
}

export const fmsOrderLiveActivityReducer = (state=orderActivity, action) => {
    switch(action?.type){
        case GET_ORDER_LIVE_ACTIVITY:
            return {...state, loading: !location, error: null};
        case GET_ORDER_LIVE_ACTIVITY_SUCCESS:
            return {...state, loading: false, location: action?.payload};
        case GET_ORDER_LIVE_ACTIVITY_ERROR:
            return {...state, loading: false, error: action?.payload};
        default:
            return state;
    }
}


const customerData = {
    loading: false,
    error: null,
    list: []
}

export const fmsCustomerReducer = (state=customerData, action) => {
    switch(action?.type){
        case GET_CUSTOMER_LIST:
            return {...state, loading: true, error: null};
        case GET_CUSTOMER_LIST_SUCCESS:
            return {...state, loading: false, list: action?.payload};
        case GET_CUSTOMER_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        case CREATE_NEW_CUSTOMER:
            return {...state, error: null,loading: true};
        case CREATE_NEW_CUSTOMER_SUCCESS:
            return {...state, error: null, loading: false};
        case CREATE_NEW_CUSTOMER_ERROR:
            return {...state, error: action?.payload, loading: false}
        default:
            return state;
    }
}

const vehicleData = {
    loading: false,
    error: null,
    list: [],
    total: 0,
    details: {}
}


export const fmsVehicleReducer = (state=vehicleData, action) => {
    switch(action?.type){
        case GET_FMS_VEHICLE_LIST:
            return {...state, loading: true, error: null};
        case GET_FMS_VEHICLE_LIST_SUCCESS:
            return {...state, loading: false, list: action?.payload?.data, total: action?.payload?.total};
        case GET_FMS_VEHICLE_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        case GET_FMS_VEHICLE_DETAILS:
            return {...state, loading: true, error: null, details: {}};
        case GET_FMS_VEHICLE_DETAILS_SUCCESS:
            return {...state, loading: false, error: null, details: action?.payload};
        case GET_FMS_VEHICLE_DETAILS_ERROR:
            return {...state, loading: false, error: action?.payload};
        default:
            return state;
    }
}

const driverData = {
    loading: false,
    error: null,
    list: []
}

export const fmsDriverReducer = (state=driverData, action) => {
    switch(action?.type){
        case GET_DRIVER_LIST:
            return {...state, loading: true, error: null};
        case GET_DRIVER_LIST_SUCCESS:
            return {...state, loading: false, list: action?.payload};
        case GET_DRIVER_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        default:
            return state;
    }
}


const cityData = {
    loading: false,
    error: null,
    city: [],
    stations: []
}

export const chargingStationReducer = (state=cityData, action) => {
    switch(action?.type){
        case GET_CHARGING_STATION_CITY_LIST:
            return {...state, loading: true, error: null};
        case GET_CHARGING_STATION_CITY_LIST_SUCCESS:
            return {...state, loading: false, city: action?.payload};
        case GET_CHARGING_STATION_CITY_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        case GET_CHARGING_STATION_LIST:
            return {...state, loading: true, error: null};
        case GET_CHARGING_STATION_LIST_SUCCESS:
            return {...state, loading: false, stations: action?.payload};
        case GET_CHARGING_STATION_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        default:
            return state;
    }
}

const walletState = {
    loading: false,
    error: null,
    transactions: [],
    wallet: {},
}

export const walletReducer = (state=walletState, action) => {
    switch (action.type) {
        case GET_WALLET_DATA:
            return {...state, loading: true, error: null};
        case GET_WALLET_DATA_SUCCESS: 
            return {...state, loading: false, transactions: action?.payload?.transaction || [], wallet: action?.payload?.data};
        case GET_WALLET_DATA_ERROR: 
            return {...state, loading: false, error: action?.payload};
        case ADD_MONEY_IN_DRIVER_WALLET:
            return {...state, loading: true, error: null};
        case ADD_MONEY_IN_DRIVER_WALLET_SUCCESS:
            return {...state, loading: false, error: null};
        case ADD_MONEY_IN_DRIVER_WALLET_ERROR:
            return {...state, loading: false, error: action?.payload};
        default:
            return state;
    }
}

const fmsUsers = {
    loading: false,
    error: null,
    list: [],
    details: {},
    total: 0
}

export const fmsUserReducer = (state=fmsUsers, action) => {
    switch (action.type) {
        case CREATE_FMS_USER:
            return {...state, loading: true, error: null};
        case CREATE_FMS_USER_SUCCESS:
            return {...state, loading: false, list: [action?.payload, ...state.list]};
        case CREATE_FMS_USER_ERROR: 
            return {...state, loading: false, error: action?.payload};
        case GET_FMS_USER_LIST:
            return {...state, loading: true, error: null};
        case GET_FMS_USER_LIST_SUCCESS:
            return {...state, loading: false, error: null, list: action?.payload?.list, total: action?.payload?.total};
        case GET_FMS_USER_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        case GET_FMS_USER_DETAILS:
            return {...state, loading: true, error: null, details: {}};
        case GET_FMS_USER_DETAILS_SUCCESS:
            return {...state, loading: false, details: action?.payload};
        case GET_FMS_USER_DETAILS_ERROR:
            return {...state, loading: false, error: action?.payload};
        case UPDATE_FMS_USER_DETAILS:
            return {...state, loading: true, error: null};
        case UPDATE_FMS_USER_DETAILS_SUCCESS:
            return {...state, loading: false, error: null};
        case UPDATE_FMS_USER_DETAILS_ERROR:
            return {...state, loading: false, error: action?.payload};
        default:
            return state;
    } 
}


const fmsRoles = {
    loading: false,
    error: null,
    roles: [],
    permissions: [],
    rolePermissions: [],
    roleInfo: {},
    total: 0
}

export const fmsRoleAndPermissionReducer = (state=fmsRoles, action) => {
    switch (action.type) {
        case GET_FMS_ROLE_LIST:
            return {...state, loading: true, error: null};
        case GET_FMS_ROLE_LIST_SUCCESS:
            return {...state, loading: false, roles: action?.payload?.list, total: action?.payload?.total};
        case GET_FMS_ROLE_LIST_ERROR: 
            return {...state, loading: false, error: action?.payload};
        case GET_FMS_PERMISSIONS_LIST:
            return {...state, loading: true, error: null};
        case GET_FMS_PERMISSIONS_LIST_SUCCESS:
            return {...state, loading: false, permissions: action?.payload};
        case GET_FMS_PERMISSIONS_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        case GET_FMS_ROLE_PERMISSIONS:
            return {...state, loading: true, error: null};
        case GET_FMS_ROLE_PERMISSIONS_SUCCESS:
            return {...state, loading: false, rolePermissions: action?.payload?.permissions, roleInfo: action?.payload?.roleInfo};
        case GET_FMS_ROLE_PERMISSIONS_ERROR:
            return {...state, loading: false, error: action?.payload};
        case CREATE_NEW_FMS_ROLE:
            return {...state, loading: true, error: null};
        case CREATE_NEW_FMS_ROLE_SUCCESS:
            return {...state, loading: false, roles: [action?.payload, ...state.roles]};
        case CREATE_NEW_FMS_ROLE_ERROR:
            return {...state, loading: false, error: action?.payload};
        case UPDATE_ROLE_DETAILS:
            return {...state, loading: true, error: null};
        case UPDATE_ROLE_DETAILS_SUCCESS:
            return {...state, loading: false};
        case UPDATE_ROLE_DETAILS_ERROR:
            return {...state, loading: false, error: action?.payload};
        case UPDATE_ROLE_PERMISSIONS:
            return {...state, loading: true, error: null};
        case UPDATE_ROLE_PERMISSIONS_SUCCESS:
            return {...state, loading: false};
        case UPDATE_ROLE_PERMISSIONS_ERROR:
            return {...state, loading: false, error: action?.payload};
        default:
            return state;
    } 
}

