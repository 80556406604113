import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import { 
  fleetbaseLoginReducer, 
  userDetails 
} from './auth/reducer';
import { 
  applicationSummaryReducer,
  breResultReducer,
  campaignDataReducer,
  constantDataReducer,
  journeyDataReducer, 
  journeyDetailsReducer, 
  journeyFormReducer, 
  journeyStepReducer, 
  productReducer, 
  stepFieldsReducer, 
  submitProductReducer
} from './journey/reducer';
import { 
  globalEVDataReducer,
  oemData, 
  partnerListReducer, 
  partnerVehicleDataReducer, 
  partnerVehicleDistanceAndCo2Reducer, 
  vehicleDetails, 
  vehicleList 
} from './dashboard/reducer';
import { 
  evDetailsReducer, 
  stateListReducer 
} from './calculator/reducer';

import { 
  chargingStationReducer,
  fmsCustomerReducer, 
  fmsDriverReducer, 
  fmsOrderLiveActivityReducer, 
  fmsOrderReducer, 
  fmsRoleAndPermissionReducer,
  fmsUserReducer, 
  fmsVehicleReducer, 
  walletReducer
} from './fms/reducer';

import { 
  permissionListReducer 
} from './permission/reducer';




const reducers = combineReducers({
  menu,
  settings,
  user: userDetails,
  journeySteps: journeyStepReducer,
  stepFields: stepFieldsReducer,
  journeyData: journeyDataReducer,
  oemData: oemData,
  vehicleDetails: vehicleDetails,
  vehicleList: vehicleList,
  journeyState: journeyFormReducer,
  offeredProducts: productReducer,
  journeyDetails: journeyDetailsReducer,
  selectProduct: submitProductReducer,
  campaignData: campaignDataReducer,
  breResult: breResultReducer,
  fleetbaseAuth: fleetbaseLoginReducer,
  globalEVData: globalEVDataReducer,
  partnerVehicleData: partnerVehicleDataReducer,
  applicationSummary: applicationSummaryReducer,
  constants: constantDataReducer,
  partnerVehicleDistanceAndCo2: partnerVehicleDistanceAndCo2Reducer,
  stateList: stateListReducer,
  evDetails: evDetailsReducer,
  fmsDriver: fmsDriverReducer,
  fmsCustomer: fmsCustomerReducer,
  fmsVehicle: fmsVehicleReducer,
  fmsOrder: fmsOrderReducer,
  fmsOrderLiveActivity: fmsOrderLiveActivityReducer,
  chargingStation: chargingStationReducer,
  permissions: permissionListReducer,
  wallet: walletReducer,
  partners: partnerListReducer,
  fmsUsers: fmsUserReducer,
  fmsRolesAndPermissions: fmsRoleAndPermissionReducer
});

export default reducers;
