import { GET_PERMISSION_LIST_SUCCESS,GET_PERMISSION_LIST,GET_PERMISSION_LIST_ERROR} from "redux/contants";


export const getPermissionList = (payload) => ({
    type: GET_PERMISSION_LIST,
    payload
});

export const getPermissionListSuccess = (payload) => {
    const {permissions, orgInfo} = payload;
    let result = {};
    for(const module of permissions){
        let obj = {};
        for(const subModule of module?.subModules){
            obj = {
                ...obj,
                [subModule.subModuleCode]: subModule.permissions
            }
        }
        result = {
            ...result,
            [module.moduleCode]: obj
        }
    }
    return {
        type: GET_PERMISSION_LIST_SUCCESS,
        payload: {permissions: result, orgInfo: orgInfo}
    }
};

export const getPermissionListError = (payload) => ({
    type: GET_PERMISSION_LIST_ERROR,
    payload
});

